import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #ffcccc;
  margin-top: 5px;
  font-size: calc(3vw + 16px);
  border: none;
  text-decoration: none;
  width: calc(20vw);
  :hover {
    cursor: pointer;
    text-decoration: line-through;
  }
`

export default class BackLink extends React.Component {
  state = {
    text: 'HOME'
  }

  onMouseEnter = () => {
    this.setState({ text: 'HOUSE' })
  }

  onMouseLeave = () => {
    this.setState({ text: 'HOME' })
  }

  render() {
    const show = !this.props.noBackButton
    return (
      show && <StyledLink
        to="/home"
        onMouseLeave={this.onMouseLeave}
        onMouseEnter={this.onMouseEnter}
      >
        {this.state.text}
      </StyledLink>
    )
  }
}
