import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import BackButton from './BackButton'

const FullWidthDiv = styled.div`
  width: 100vw;
  background-color: #222;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 9999;
  position: fixed;
  top: 0;
  height: calc(4vw + 32px);
  background-color: #060606;
  border-bottom: 1px solid #060606;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #affdd3;
  margin-top: 5px;
  font-size: calc(1vw + 10px);
  border: none;
  text-decoration: none;
  letter-spacing: 5px;
  padding: 0 5px;
  :hover {
    cursor: pointer;
    text-decoration: line-through;
  }
  &.active {
    text-decoration: line-through;
    cursor: default;
  }

  @media (max-width: 500px) {
    &.active {
      display: none;
    }
}
`

const TopBanner = ({ title, noBackButton }) => (
  <FullWidthDiv title={title}>
    <StyledLink className={title === 'sounds' ? 'active' : ''} to="/sounds">
      SOUNDS
    </StyledLink>
    <StyledLink className={title === 'events' ? 'active' : ''} to="/events">
      EVENTS
    </StyledLink>
    <StyledLink className={title === 'booking' ? 'active' : ''} to="/booking">
      BOOKING
    </StyledLink>
    <StyledLink className={title === 'about' ? 'active' : ''} to="/bio">
      ABOUT
    </StyledLink>
    <BackButton title={title} noBackButton={noBackButton} />
  </FullWidthDiv>
)

export default TopBanner
