import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { navigateTo } from 'gatsby-link'
import Layout from '../../components/Layout'
import TopBanner from '../../components/TopBanner'

const StyledEventInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: calc(40vw - 35px);
  height: 100%;
  background: transparent;
  padding: 10px;
  width: 100%;
  border-radius: 0 3px 3px 0;
  @media (max-width: 768px) {
    flex: 1;
    border-radius: 0 0 3px 3px;
  }
`

const flicker = keyframes`
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70% {
    text-shadow: 15px -15px #ffabab26, -15px -15px #5991e626, 0 -10px beige;
    opacity: 0.9;
  }
  20%, 21.999%, 63% {
    text-shadow: 10px -10px #ffabab26, -10px -10px #5991e626, 0 -10px beige;
    opacity: 0.4;
  }
  63.999%, 65%, 69.999% {
    text-shadow: 5px -5px #ffabab26, -5px -5px #5991e626, 0 -10px beige;
    opacity: 0.9;
  }
  100% {
    color: #affdd3;
    text-shadow: none;
  }
`

const EventTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8vh;
  font-size: calc(18px + 1vw);
  letter-spacing: 1vw;
  background: rgb(34, 34, 34);
  color: rgb(34, 34, 34);
  writing-mode: vertical-lr;
  width: 5vw;
  position: relative;
  border-radius: 3px 0 0 3px;
  text-shadow: 1px 0px 3px black;
  text-decoration: line-through;
  @media (max-width: 768px) {
    border-radius: 3px 3px 0 0;
    writing-mode: inherit;
    width: 100%;
  }
`

const EventTitle2 = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(12px + 1vw);
  line-height: calc(12px);
  text-align: center;
  text-shadow: 1px 0px 3px black;
  text-decoration: line-through;
  background: rgb(34, 34, 34);
  color: rgb(34, 34, 34);
  letter-spacing: 1.5vw;
  text-shadow: 1px 0px 3px black;
  @media (max-width: 768px) {
    display: none;
  }
`

const EventTitle3 = styled.h2`
  display: flex;
  color: #ffcccc;
  justify-content: center;
  align-items: center;
  font-size: calc(8px + 1vw);
  letter-spacing: 0.75vw;
`

const NarrowInfo = styled.div`
  width: 100%;
  color: transparent;
  justify-content: center;
  align-items: center;
  padding: 1vh 0;
  display: flex;
  text-transform: uppercase;
  text-shadow: 15px -15px #ffabab26, -15px -15px #5991e626, 0 -10px #affdd3;
  animation: ${flicker} 0.5s;
  animation-fill-mode: forwards;
  font-size: calc(12px + 1vw);
  ${props => props.date && 'font-size: calc(10px + 1vw);'}
}
`

const WideInfo = styled.div`
  display: flex;
  width: 100%;
  line-height: calc(16px + 2vw);
  font-size: calc(6px + 0.75vw);
  color: #eceae5;
  border-radius: 2px;
  border: 1px solid #e6c992;
  padding: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.23);
`
const StyledImageContainer = styled.div`
  ${'' /* height: 100%; */}
  max-height: 79vh;
  min-width: calc(55vw - 35px);
  ${'' /* width: 100%; */}
  display: flex;
  flex-grow: 1;
  border: 1px solid #ffcccc;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.23);;
  @media (max-width: 768px) {
    min-width: calc(70vw - 35px);
  }
`

const StyledLink = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0 3px 3px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 0 0 3px 3px;
  }
`
const StyledContainer = styled.section`
  display: flex;
  border: 1px solid #affdd3;
  box-shadow: 1px 0px 3px black;
  padding: 25px;
  background-color: rgb(34, 34, 34);
  transition: box-shadow 1s ease;
  :hover {
    cursor: pointer;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    transition: box-shadow 1s ease;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
const StyledEvents = styled.div`
  background-color: rgb(34, 34, 34);
  overflow-x: hidden;
`

// to={event.fields.slug}
const Events = ({ data }) => {
  const events = data.allMarkdownRemark.edges

  return (
    <Layout hasPadding>
      <TopBanner title="events" />
      <StyledEvents>
        {(events.sort((one, two) => new Date(two.node.frontmatter.event.date) - new Date(one.node.frontmatter.event.date)).map(({ node: event }) => (
          <StyledContainer
            key={event.id}
            onClick={() => navigateTo(event.fields.slug)}
          >
            <EventTitle>
              {event.frontmatter.title}
            </EventTitle>
            <StyledLink>
              <StyledImageContainer>
                <Img
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ width: '100%' }}
                  fluid={event.frontmatter.poster.childImageSharp.fluid}
                />
              </StyledImageContainer>
              <StyledEventInfoContainer>
                <NarrowInfo>
                  <EventTitle2>
                    {event.frontmatter.title}
                  </EventTitle2>
                </NarrowInfo>
                <NarrowInfo>
                  <EventTitle3>
                    {event.frontmatter.title}
                  </EventTitle3>
                </NarrowInfo>
                <NarrowInfo date>
                  {event.frontmatter.event.date}
                </NarrowInfo>
                <NarrowInfo>{event.frontmatter.venue}</NarrowInfo>
                <NarrowInfo>{event.frontmatter.location}</NarrowInfo>
                {event.excerpt.length > 0 && <WideInfo>{event.excerpt}</WideInfo>}
              </StyledEventInfoContainer>
            </StyledLink>
          </StyledContainer>
        )))}
      </StyledEvents>
    </Layout>
  )
}

Events.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___event___date] },
      filter: { frontmatter: { templateKey: { eq: "event-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 150)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            venue
            location
            poster {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            event {
              date
            }
          }
        }
      }
    }
  }
`

export default Events
